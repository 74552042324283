/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Ategal video 2022
* @version 01.10.00
* @license licenses.txt
*
* @date 2022-09-06 11:37:29
**/

import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

const routes: Routes = [
	{
		path: "",
		redirectTo: "bancos",
		pathMatch: "full"
	},
	{
		path: "abanca-ingresar-dinero",
		loadChildren: () => import("./pages/abanca-ingresar-dinero/abanca-ingresar-dinero.module").then(m => m.AbancaIngresarDineroPageModule)
	},
	{
		path: "abanca-inicio",
		loadChildren: () => import("./pages/abanca-inicio/abanca-inicio.module").then(m => m.AbancaInicioPageModule)
	},
	{
		path: "abanca-pass-vigo",
		loadChildren: () => import("./pages/abanca-pass-vigo/abanca-pass-vigo.module").then(m => m.AbancaPassVigoPageModule)
	},
	{
		path: "abanca-sacar-dinero",
		loadChildren: () => import("./pages/abanca-sacar-dinero/abanca-sacar-dinero.module").then(m => m.AbancaSacarDineroPageModule)
	},
	{
		path: "about-us",
		loadChildren: () => import("./pages/about-us/about-us.module").then(m => m.AboutUsPageModule)
	},
	{
		path: "bancos",
		loadChildren: () => import("./pages/bancos/bancos.module").then(m => m.BancosPageModule)
	},
	{
		path: "bbva-ingresar-dinero",
		loadChildren: () => import("./pages/bbva-ingresar-dinero/bbva-ingresar-dinero.module").then(m => m.BbvaIngresarDineroPageModule)
	},
	{
		path: "bbva-inicio",
		loadChildren: () => import("./pages/bbva-inicio/bbva-inicio.module").then(m => m.BbvaInicioPageModule)
	},
	{
		path: "bbva-retirar-dinero",
		loadChildren: () => import("./pages/bbva-retirar-dinero/bbva-retirar-dinero.module").then(m => m.BbvaRetirarDineroPageModule)
	},
	{
		path: "bbva-transferir",
		loadChildren: () => import("./pages/bbva-transferir/bbva-transferir.module").then(m => m.BbvaTransferirPageModule)
	},
	{
		path: "caixabank-ingresar-dinero",
		loadChildren: () => import("./pages/caixabank-ingresar-dinero/caixabank-ingresar-dinero.module").then(m => m.CaixabankIngresarDineroPageModule)
	},
	{
		path: "caixabank-inicio",
		loadChildren: () => import("./pages/caixabank-inicio/caixabank-inicio.module").then(m => m.CaixabankInicioPageModule)
	},
	{
		path: "caixabank-retirar-dinero",
		loadChildren: () => import("./pages/caixabank-retirar-dinero/caixabank-retirar-dinero.module").then(m => m.CaixabankRetirarDineroPageModule)
	},
	{
		path: "caixabank-transferir-dinero",
		loadChildren: () => import("./pages/caixabank-transferir-dinero/caixabank-transferir-dinero.module").then(m => m.CaixabankTransferirDineroPageModule)
	},
	{
		path: "faqs",
		loadChildren: () => import("./pages/faqs/faqs.module").then(m => m.FaqsPageModule)
	},
	{
		path: "home",
		loadChildren: () => import("./pages/home/home.module").then(m => m.HomePageModule)
	},
	{
		path: "iniciov2",
		loadChildren: () => import("./pages/iniciov2/iniciov2.module").then(m => m.Iniciov2PageModule)
	},
	{
		path: "iniciov3",
		loadChildren: () => import("./pages/iniciov3/iniciov3.module").then(m => m.Iniciov3PageModule)
	},
	{
		path: "privacidad",
		loadChildren: () => import("./pages/privacidad/privacidad.module").then(m => m.PrivacidadPageModule)
	},
	{
		path: "privacy-policy",
		loadChildren: () => import("./pages/privacy-policy/privacy-policy.module").then(m => m.PrivacyPolicyPageModule)
	},
	{
		path: "sabadell-ingresar-dinero",
		loadChildren: () => import("./pages/sabadell-ingresar-dinero/sabadell-ingresar-dinero.module").then(m => m.SabadellIngresarDineroPageModule)
	},
	{
		path: "sabadell-inicio",
		loadChildren: () => import("./pages/sabadell-inicio/sabadell-inicio.module").then(m => m.SabadellInicioPageModule)
	},
	{
		path: "sabadell-retirar-dinero",
		loadChildren: () => import("./pages/sabadell-retirar-dinero/sabadell-retirar-dinero.module").then(m => m.SabadellRetirarDineroPageModule)
	},
	{
		path: "sabadell-transferencia",
		loadChildren: () => import("./pages/sabadell-transferencia/sabadell-transferencia.module").then(m => m.SabadellTransferenciaPageModule)
	},
	{
		path: "santander-ingresar-dinero",
		loadChildren: () => import("./pages/santander-ingresar-dinero/santander-ingresar-dinero.module").then(m => m.SantanderIngresarDineroPageModule)
	},
	{
		path: "santander-inicio",
		loadChildren: () => import("./pages/santander-inicio/santander-inicio.module").then(m => m.SantanderInicioPageModule)
	},
	{
		path: "santander-retirar-dinero",
		loadChildren: () => import("./pages/santander-retirar-dinero/santander-retirar-dinero.module").then(m => m.SantanderRetirarDineroPageModule)
	},
	{
		path: "santander-transferencia",
		loadChildren: () => import("./pages/santander-transferencia/santander-transferencia.module").then(m => m.SantanderTransferenciaPageModule)
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
