/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Ategal video 2022
* @version 01.10.00
* @license licenses.txt
*
* @date 2022-09-06 11:37:30
**/

import { Directive, HostListener, Input, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StreamingMedia } from "@ionic-native/streaming-media/ngx";
import { StreamingVideoOptions } from "@ionic-native/streaming-media/ngx";
import { StreamingAudioOptions } from "@ionic-native/streaming-media/ngx";
import { Platform } from "@ionic/angular";
import { AlertController } from "@ionic/angular";

@Directive({
	selector: "[streamingMedia]"
})

export class StreamingMediaDirective {

	@Input() url: string;
	@Input() format: string;
	@Input() orientation: string;
	@Input() controls: boolean;

	constructor( 
		private elementRef: ElementRef,
		public translateService: TranslateService,
		private streamingMedia: StreamingMedia,
		private platform: Platform,
		private alertController: AlertController
	 ){


	}






	@HostListener("click", ["$event"]) onClick(e){
			
		if (this.platform.is("cordova")){
			this.playMedia();
		}else{
			this.showAlert("Only support on real Device!");
		}

	}


	
	/**
	* videoOption()
	**/
	
	private videoOption(): StreamingVideoOptions{
		
		let mediaControls = this.controls || false ;
		let mediaOrientation = this.orientation || "landscape" ;
		if (mediaOrientation == ""){
			mediaOrientation = "landscape";
		}
		let options: StreamingVideoOptions = {
			successCallback:()=>{
				console.log("Video played");
			},
			errorCallback:(e)=>{
				console.log("Error streaming");
			},
			orientation: mediaOrientation,
			shouldAutoClose: true
		};
		
		if (this.platform.is("android")){
			if (mediaControls == true){
				options.controls = true;
			}else{
				options.controls = false;
			}
		}
		return options;
	}
	
	
	/**
	* audioOption()
	**/
	
	private audioOption(): StreamingAudioOptions{
		let options: StreamingAudioOptions = {
			bgImage: "assets/images/background/bg.jpg",
			bgColor: "#DEBDD6",
			bgImageScale: "stretch",
			keepAwake: false,
			initFullscreen: false,
			successCallback:()=>{
				console.log("Audio played");
			},
			errorCallback:(e)=>{
				console.log("Error streaming");
			},
		}
		return options;
	}
	
	
	/**
	* playMedia()
	**/
	
	private playMedia(){
	
		let mediaFormat = this.format || "video" ;
		if (mediaFormat !== "audio"){
			mediaFormat = "video";
		}
	
		if(mediaFormat == "video"){
			let options:StreamingVideoOptions = this.videoOption();
			this.streamingMedia.playVideo(this.url, options);
		}else{
			let options:StreamingAudioOptions = this.audioOption();
			this.streamingMedia.playAudio(this.url, options);
		}
	}
	
	
	/**
	* showAlert($text)
	* @param string $text = "hi..."
	**/
	
	async showAlert(text:string){
		const alert = await this.alertController.create({
			header: "Streaming Media",
			subHeader: "Information!",
			message: text,
			buttons: ["OK"]
		});
		await alert.present();
	}


}
