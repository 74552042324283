/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Ategal video 2022
* @version 01.10.00
* @license licenses.txt
*
* @date 2022-09-06 11:37:30
**/

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Storage } from "@ionic/storage-angular";


@Injectable({providedIn:"root"})
export class TranslateConfiguration {
	
	constructor(
		public translateService: TranslateService,
		public storage: Storage
	){

		
		this.storageInit();
		this.setDefaultLanguage();


	}



	
	current_language:string = "es";
	
	
	/**
	* init storage
	**/
	async storageInit(){
		await this.storage.create();
	}
	
	
	/**
	* Set Locale
	**/
	set locale(value: string) {
		this.current_language = value;
	}
	
	
	/**
	* Get Locale
	**/
	get locale(): string {
		return this.current_language || "es";
	}
	
	
	/**
	* TranslateService:setDefaultLanguage()
	**/
	setDefaultLanguage(){
		let language = this.current_language;
		this.storage.get(`locale`).then(new_language=>{
			if(new_language != null){
				language = new_language;
				this.current_language = new_language;
			}
			console.log(`TranslateConfiguration`,`setDefaultLanguage`,language);
			this.translateService.setDefaultLang(language);
		});
	}
	
	
	/**
	* TranslateService:setLanguage(setLang:string)
	**/
	setLanguage(setLang:string){
		console.log(`TranslateConfiguration`,`setLanguage`,setLang);
		this.translateService.use(setLang);
	}
	



}
