/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Ategal video 2022
* @version 01.10.00
* @license licenses.txt
*
* @date 2022-09-06 11:37:29
**/

import { Component } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { AppSideMenus } from "./app.side-menus";

import { Platform, NavController } from "@ionic/angular";
import { Storage } from "@ionic/storage-angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { InAppBrowser,InAppBrowserOptions } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from "@angular/router";
import { Device } from "@awesome-cordova-plugins/device/ngx";
import { Globals } from "./class/globals/globals";
import { TranslateConfiguration } from "./class/translate-configuration/translate-configuration";
import { environment } from "./../../src/environments/environment";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { ToastController } from "@ionic/angular";
import { OneSignal } from "@awesome-cordova-plugins/onesignal/ngx";


/**
* OneSignal Setting
**/
const oneSignalAppId = "2c83b799-fd6e-42ef-a912-eef95095784e"; 
const firebaseSenderID = "540346665823"; 



@Component({
	selector: "app-root",
	templateUrl: "app.component.html"
})
export class AppComponent {

	appTitle:string = "Ategal App";
	appSubTitle:string = "Axuda para maiores";
	appMenus:any = [] ;



	/**
	* AtegalVideo:constructor()
	**/

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private menuController: MenuController,
		private platform: Platform,
		private storage: Storage,
		private statusBar: StatusBar,
		private navController: NavController,
		private splashScreen: SplashScreen,
		private device: Device,
		private appSideMenus: AppSideMenus,
		private window: Window,
		private globals: Globals,
		private translateConfiguration: TranslateConfiguration,
		private network: Network,
		private toastController: ToastController,
		private oneSignal: OneSignal){
			//initialization items for static menu
			this.appMenus = this.appSideMenus.items;
			//initialization app
			this.initializeApp();
	}
	

	/**
	* AtegalVideo:initializeApp()
	**/

	initializeApp() {
		this.platform.ready().then(() => {

			// set status bar
			this.statusBar.backgroundColorByHexString("#000000");

			// hide splashscreen
			this.splashScreen.hide();

			// ionic storage
			this.storageInit();
			// translate
			this.translateConfiguration.setDefaultLanguage();
			this.router.events.subscribe((event: Event) =>{
				if(event instanceof NavigationStart){
					this.translateConfiguration.setDefaultLanguage();
				};
			});


		});


		// This code is used for No Network
this.handlerNoNetwork();

		// This code is used for One Signal
		this.handlerNotifications();

		this.handlerBackButton();
		
	}


	/**
	* AtegalVideo:storageInit();
	**/
	async storageInit(){
		await this.storage.create();
	}


	/**
	* AtegalVideo:handlerBackButton();
	**/
	private handlerBackButton(){
		let pageName = `bancos`;
		this.router.events.subscribe((event: Event) =>{
			if(event instanceof NavigationStart){
				let getPage = event.url.toString().split("/");
				pageName = getPage[1];
				console.log(`pageName`,pageName);
			}
		});
		this.platform.backButton.subscribeWithPriority(666666,()=>{
			if(( pageName == "" ) || ( pageName == `bancos` )){
				console.log(`Hardware Exit`);
				if(window.confirm("Do you want to exit App?")){
					navigator["app"].exitApp(); 
				}
			}else{
				console.log(`Hardware Back`);
				this.navController.back();
			}
		})
	}


/**
	* AtegalVideo:handlerNoNetwork()
	**/
	public handlerNoNetwork(){
	
		this.platform.ready().then(() =>{
			if (this.platform.is("cordova")){
			
				let isOnline:boolean = true; 
				let currentPage:string = "bancos"; 
				
				this.router.events.subscribe((event: Event) =>{
					if(event instanceof NavigationStart){
						let getPage = event.url.toString().split("/");
						let pageName = getPage[1];
						currentPage = pageName;
					}
				});
				
				
				setInterval(() => {
					if (isOnline == false) {
						if((currentPage == "santander-ingresar-dinero") || 
							(currentPage == "santander-retirar-dinero") || 
							(currentPage == "santander-transferencia")){
							this.showToastNoNetwork("No tienes internet.");
						}
					}
					console.log("internet",currentPage,isOnline);
				}, 200);
				
				
				// watch network for a disconnection
				let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
					this.showToastNoNetwork("No tienes internet.");
					isOnline = false;
				});
				
				
				// watch network for a connection
				let connectSubscription = this.network.onConnect().subscribe(() => {
					this.showToastNoNetwork("Tienes internet");
					if (this.network.type == "wifi") {
						this.showToastNoNetwork("Tienes conexión wifi, no consumes datos");
					}
					isOnline = true;
				});
				
	
	
			}
		});
	}
	
	
	/**
	* AtegalVideo:showToast()
	**/
	async showToastNoNetwork(message:string){
		const toast = await this.toastController.create({
			message: message,
			position: "bottom",
			color: "secondary",
			duration: 500
		});
		await toast.present();
	}
	/**
	* AtegalVideo:handlerNotifications()
	**/
	public handlerNotifications(){
		this.platform.ready().then(() =>{
			if (this.platform.is("cordova")){
				if (this.platform.is("android")){
					this.oneSignal.startInit(oneSignalAppId,firebaseSenderID);
				}else{
					this.oneSignal.startInit(oneSignalAppId);
				}
				this.oneSignal.endInit();
			}
		});
	}


}
