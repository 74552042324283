/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Ategal video 2022
* @version 01.10.00
* @license licenses.txt
*
* @date 2022-09-06 11:37:30
**/

import { Injectable } from "@angular/core";


@Injectable()
export class Globals {
	
	constructor(
		
	){



	}



	devInfo: any = {};


}
